// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  backendApiUrl:
    'https://lekdetectiezuid-test.eu-central-1.elasticbeanstalk.com',
  googleFireAuthSignUpUrl:
    'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=',
  googleFireAuthLoginUrl:
    'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=',
  googleFireApiKey: 'AIzaSyBWoYIJSmrxBBj9xY5EXQpq6mD7Zm_6mAE',
  firebaseConfig: {
    apiKey: 'AIzaSyBWoYIJSmrxBBj9xY5EXQpq6mD7Zm_6mAE',
    authDomain: 'lzbackend.firebaseapp.com',
    databaseURL: 'https://lzbackend.firebaseio.com',
    projectId: 'lzbackend',
    storageBucket: 'lzbackend.appspot.com',
    messagingSenderId: '613642871508',
    appId: '1:613642871508:web:52a802402edbff271c5141',
  },

  awsBucketName: 'lekdetectie-files',
  awsDefaultRegion: 'eu-central-1',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
